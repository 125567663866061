<template>
  <div class="row h-100 justify-content-between">
    <div
      class="d-none d-lg-block col-lg-2 col-xl-2 text-white align-items-stretch bg-white"
    >
      <div class="pl-4">
        <Phaseline
          :phases="process.processPhases"
          :processId="process.id"
          :processLogo="getProcessLogo"
        >
          >
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-10 col-xl-10">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-5 shadow-sm">
          <div class="card-header border-0 pb-4">
            <div class="row">
              <div class="col-12">
                <h3
                  class="text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1"
                >
                  {{ phase.title }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column px-6 mb-5">
        <div class="card card-custom card-transparent">
          <div class="card-body p-0">
            <!--: Wizard-->
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--: Wizard Nav-->
              <div class="wizard-nav">
                <div class="wizard-steps justify-content-start">
                  <!--Istruzioni-->
                  <!--                  <div v-if="true" class="wizard-step col-12 col-md-3 col-lg-3 col-xl-3" data-wizard-type="step" data-wizard-state="current">-->
                  <div
                    v-if="
                      this.contentTypeInstructions &&
                      this.contentTypeInstructions != ''
                    "
                    class="wizard-step col-12 col-md-3 col-lg-3 col-xl-3"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <!--                      <div class="wizard-number">-->
                      <!--                        1-->
                      <!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
                          <!--                          {{$t("MODULEIDEACREATE.ISTRUZIONI")}}-->
                          {{ stepA }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Sfide-->
                  <!--                  <div class="wizard-step col-12" :class="{'col-md-2 col-lg-2 col-xl-2': this.contentTypeInstructions && this.contentTypeInstructions!='', 'col-md-3 col-lg-3 col-xl-3': this.contentTypeInstructions == ''}" data-wizard-type="step" :data-wizard-state="(this.contentTypeInstructions && this.contentTypeInstructions!='') ? '' : 'current'"-->
                  <!--                    v-if="true"-->
                  <!--                  >-->
                  <div
                    class="wizard-step col-12"
                    :class="{
                      'col-md-2 col-lg-2 col-xl-2':
                        this.contentTypeInstructions &&
                        this.contentTypeInstructions != '',
                      'col-md-3 col-lg-3 col-xl-3':
                        this.contentTypeInstructions == '',
                    }"
                    data-wizard-type="step"
                    :data-wizard-state="
                      this.contentTypeInstructions &&
                      this.contentTypeInstructions != ''
                        ? ''
                        : 'current'
                    "
                    v-if="challenges && challenges.length > 0"
                  >
                    <div class="wizard-wrapper">
                      <!--                      <div class="wizard-number">-->
                      <!--                        {{ istruzioniValue + 1 }}-->
                      <!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
                          <!--                          {{$t("MODULEIDEACREATE.CHALLENGE")}}-->
                          {{ stepB }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Contesto-->
                  <!--                  <div v-if="true" class="wizard-step col-12" :class="{'col-md-2 col-lg-2 col-xl-2': this.contentTypeInstructions && this.contentTypeInstructions!='', 'col-md-3 col-lg-3 col-xl-3': this.contentTypeInstructions == ''}" data-wizard-type="step">-->
                  <div
                    v-if="(scenarios && scenarios.length > 0) || displayViewMap"
                    class="wizard-step col-12"
                    :class="{
                      'col-md-2 col-lg-2 col-xl-2':
                        this.contentTypeInstructions &&
                        this.contentTypeInstructions != '',
                      'col-md-3 col-lg-3 col-xl-3':
                        this.contentTypeInstructions == '',
                    }"
                    data-wizard-type="step"
                  >
                    <div class="wizard-wrapper">
                      <!--                      <div class="wizard-number">-->
                      <!--                        {{ istruzioniValue + challengesValue + 1 }}-->
                      <!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
                          <!--                          {{$t("MODULEIDEACREATE.MAPPEEINFO")}}-->
                          {{ stepC }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Descrizione-->
                  <div
                    class="wizard-step col-12"
                    :class="{
                      'col-md-2 col-lg-2 col-xl-2':
                        this.contentTypeInstructions &&
                        this.contentTypeInstructions != '',
                      'col-md-3 col-lg-3 col-xl-3':
                        this.contentTypeInstructions == '',
                    }"
                    data-wizard-type="step"
                  >
                    <div class="wizard-wrapper">
                      <!--                      <div class="wizard-number">-->
                      <!--                        {{ overviewValue }}-->
                      <!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
                          <!--                          {{$t("MODULEIDEACREATE.OVERVIEW")}}-->
                          {{ stepD }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Dettaglio-->
                  <div
                    class="wizard-step col-12 col-md-3 col-lg-3 col-xl-3"
                    data-wizard-type="step"
                  >
                    <div class="wizard-wrapper">
                      <!--                      <div class="wizard-number">-->
                      <!--                        {{ narrazioneValue }}-->
                      <!--                      </div>-->
                      <div class="wizard-label">
                        <div class="wizard-title">
                          <!--                          {{$t("MODULE" + $handleContentTypeLabel(moduleContentType) + "CREATE.NARRAZIONE")}}-->
                          {{ stepE }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--: Wizard Body-->
              <div class="card card-custom card-shadowless rounded-top-0 pb-10">
                <div class="card-body p-0 m-0">
                  <div class="row justify-content-center">
                    <div class="col-xl-10">
                      <form ref="ideaForm" class="form mt-10 p-10" id="kt_form">
                        <!--Istruzioni-->
                        <!--                        <div class="pb-5" v-if="true" data-wizard-type="step-content" data-wizard-state="current">-->
                        <div
                          class="pb-5"
                          v-if="
                            this.contentTypeInstructions &&
                            this.contentTypeInstructions != ''
                          "
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{ $t("MODULEIDEACREATE.ISTRUZIONI") }}
                          </div>
                          <div class="form-group">
                            <div
                              class="row col-12"
                              v-html="this.contentTypeInstructions"
                            ></div>
                          </div>
                        </div>

                        <!--Sfide-->
                        <!--                        <div class="pb-5" data-wizard-type="step-content" :data-wizard-state="(this.contentTypeInstructions && this.contentTypeInstructions!='') ? '' : 'current'" v-if="true">-->
                        <div
                          class="pb-5"
                          data-wizard-type="step-content"
                          :data-wizard-state="
                            this.contentTypeInstructions &&
                            this.contentTypeInstructions != ''
                              ? ''
                              : 'current'
                          "
                          v-if="challenges && challenges.length > 0"
                        >
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{ isBandoZac ? "Format di innovazione" : $t("MODULEIDEACREATE.CHALLENGE") }}
                          </div>
                          <div class="font-weight-bold text-dark h3">
                            {{ $t("MODULEIDEACREATE.SELEZIONATRA") }}
                            <b>{{ challenges.length }}</b>
                            {{ isBandoZac ? "Format di innovazione" : $t("MODULEIDEACREATE.CHALLENGE") }}
                          </div>
                          <p class="mb-10">
                            {{
                              $t(
                                "MODULE" +
                                  $handleContentTypeLabel(moduleContentType) +
                                  "CREATE.SCEGLISFIDE"
                              )
                            }}
                          </p>
                          <div class="row" v-for="a in agendas" :key="a.id">
                            <div class="col-12">
                              <h4>{{ a.name }}</h4>
                            </div>
                            <div
                              class="col-12 col-lg-6"
                              v-for="item in a.challenges"
                              :key="item.id"
                            >
                              <label class="option" style="min-height: 120px">
                                <span class="option-head">
                                  <span class="option-title">
                                    <img
                                      :src="
                                        item.icon && item.icon.uri
                                          ? item.icon.uri
                                          : $imageOptions.defaultLogoUrl
                                      "
                                      class="align-self-center"
                                      style="
                                        height: 100px;
                                        width: auto;
                                        max-width: 150px;
                                        border-radius: 0.42rem;
                                        object-fit: contain !important;
                                      "
                                    />
                                  </span>
                                </span>

                                <span class="option-label ml-3">
                                  <span class="option-head">
                                    <span class="option-title w-100">
                                      <span class="option-focus w-100">{{
                                        item.name
                                      }}</span>
                                    </span>
                                  </span>
                                </span>

                                <span class="option-control">
                                  <span class="checkbox">
                                    <input
                                      type="checkbox"
                                      class="ml-5"
                                      :id="'challenge-' + item.id"
                                      :value="item.id"
                                      v-model="$v.form.challenges.$model"
                                    />
                                    <span></span>
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div class="col-12"><hr /></div>
                          </div>
                        </div>

                        <!--Contesto-->
                        <!--                        <div class="pb-5" data-wizard-type="step-content" v-if="true">-->
                        <div
                          class="pb-5"
                          data-wizard-type="step-content"
                          v-if="
                            (scenarios && scenarios.length > 0) ||
                            displayViewMap
                          "
                        >
                          <div class="font-weight-bold text-dark h2">
                            {{ $t("MODULEIDEACREATE.MAPPEEINFO") }}
                          </div>
                          <!-- se content-type == tattiche, ".selezionamappa non si deve vedere -->
                          <p
                            v-if="$doNOTShowIfTactic(moduleContentType)"
                            class="mb-10"
                          >
                            {{
                              $t(
                                "MODULE" +
                                  $handleContentTypeLabel(moduleContentType) +
                                  "CREATE.SELEZIONAMAPPA"
                              )
                            }}
                          </p>
                          <div v-else class="mb-10"></div>

                          <div
                            class="form-group"
                            v-if="scenarios && scenarios.length > 0"
                          >
                            <div class="row d-flex flex-row">
                              <div
                                class="col-12 col-lg-6 align-items-stretch"
                                v-for="item in scenarios"
                                :key="item.id"
                              >
                                <label class="option" style="min-height: 120px">
                                  <!--Image-->
                                  <span class="option-head">
                                    <span class="option-title">
                                      <img
                                        :src="
                                          item.background && item.background.uri
                                            ? item.background.uri
                                            : $imageOptions.defaultLogoUrl
                                        "
                                        class="align-self-center"
                                        style="
                                          height: 100px;
                                          width: auto;
                                          max-width: 150px;
                                          border-radius: 0.42rem;
                                          object-fit: contain !important;
                                        "
                                      />
                                    </span>
                                  </span>

                                  <!--Title-->
                                  <span class="option-label ml-3">
                                    <span class="option-head">
                                      <span class="option-title w-100">
                                        <span class="option-focus w-100">{{
                                          item.title
                                        }}</span>
                                      </span>
                                    </span>
                                  </span>

                                  <!--Check-->
                                  <span
                                    class="option-control"
                                    style="margin-top: -5px"
                                  >
                                    <span class="checkbox">
                                      <input
                                        type="checkbox"
                                        class="ml-5"
                                        :id="'scenario-' + item.id"
                                        :value="item.id"
                                        v-model="$v.form.scenarios.$model"
                                      />
                                      <span></span>
                                    </span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group" v-if="displayViewMap">
                            <div class="row d-flex flex row">
                              <div style="width: 100%">
                                <gmap-autocomplete
                                  v-bind:placeholder="
                                    $t('MODULEIDEACREATE.PH3')
                                  "
                                  @place_changed="setPlace"
                                  @change="setPlace"
                                  :select-first-on-enter="true"
                                  class="form-control form-control-solid form-control-lg"
                                  style="width: 100%; margin-bottom: 20px"
                                >
                                </gmap-autocomplete>
                              </div>
                              <Gmap-Map
                                :center="myCoordinates"
                                :zoom="zoom"
                                ref="mapRef"
                                :options="{
                                  zoomControl: true,
                                  mapTypeControl: false,
                                  scaleControl: false,
                                  streetViewControl: false,
                                  rotateControl: false,
                                  fullscreenControl: false,
                                  disableDefaultUi: false,
                                }"
                                @dragend="handleDrag"
                                style="width: 100%; height: 500px"
                              >
                                <gmap-marker
                                  v-if="this.place"
                                  label=""
                                  :position="{
                                    lat: this.place.geometry.location.lat(),
                                    lng: this.place.geometry.location.lng(),
                                  }"
                                >
                                </gmap-marker>
                              </Gmap-Map>
                            </div>
                          </div>
                        </div>

                        <!--Descrizione-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{ $t("MODULEIDEACREATE.OVERVIEW") }}
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">{{
                              $t("MODULEIDEACREATE.TITOLO")
                            }} <b class="text-danger">*</b></label>
                            <b-form-input
                              type="text"
                              class="form-control-solid"
                              size="lg"
                              name="title"
                              :placeholder="$t('MODULEIDEACOMMONS.PH0')"
                              v-model="$v.form.title.$model"
                              :state="validateState('title')"
                              :maxlength="$ideaForm.title.maxLength"
                            ></b-form-input>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.DAIBREVETITOLO"
                                )
                              }}
                            </div>
                            <div
                              v-if="isMaxLengthErrorVisible('title')"
                              :class="[
                                {
                                  'text-danger': hasReachTheLimit('title'),
                                  'text-muted': !hasReachTheLimit('title'),
                                },
                                'form-text',
                              ]"
                            >
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART1") }}
                              <span class="font-size-lg font-weight-bold">{{
                                howManyCharactersLleft("title")
                              }}</span>
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART2") }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">{{
                              $t("MODULEIDEACREATE.DESCRIZIONE")
                            }} <b class="text-danger">*</b></label>
                            <!-- <vue-editor
                              id="edit"
                              :editor-toolbar="customToolbar"
                              :editor-options="{ modules: { toolbar: false } }"
                              v-bind:placeholder="$t('MODULEIDEACREATE.PH1')"
                              :tag="'textarea'"
                              v-model="$v.form.description.$model"
                              :state="validateState('description')"
                              row="10"
                              ref="editorDescription"
                              @text-change="onTextChange"
                            >
                            </vue-editor> -->
                            <b-form-textarea
                              id="edit"
                              v-model="$v.form.description.$model"
                              :placeholder="$t('MODULEIDEACREATE.PH1')"
                              rows="10"               
                              :state="validateState('description')"      
                            ></b-form-textarea>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.DESCRIVI"
                                )
                              }}
                            </div>
                            <div
                              v-if="isMaxLengthErrorVisible('description')"
                              :class="[
                                {
                                  'text-danger':
                                    hasReachTheLimit('description'),
                                  'text-muted':
                                    !hasReachTheLimit('description'),
                                },
                                'form-text',
                              ]"
                            >
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART1") }}
                              <span class="font-size-lg font-weight-bold">{{
                                howManyCharactersLleft("description")
                              }}</span>
                              {{ $t("MODULEIDEACOMMONS.MAXLENGTHPART2") }}
                            </div>
                          </div>
                          <div
                            class="form-group"
                            v-if="$showElement(moduleContentType)"
                          >
                            <label class="font-weight-bolder">{{
                              $t("MODULEIDEACREATE.COSTOIPOTIZZATO")
                            }}</label>
                            <input
                              type="number"
                              min="0"
                              max="1000000000"
                              :class="[
                                'form-control form-control-solid form-control-lg',
                                $v.form.budgetMin.$dirty &&
                                ($v.form.budgetMin.$error ||
                                  hasForbiddenCharacters)
                                  ? 'is-invalid'
                                  : '',
                              ]"
                              name="budgetMin"
                              placeholder="€"
                              v-model="$v.form.budgetMin.$model"
                              :state="validateState('budgetMin')"
                              @wheel="(e) => e.target.blur()"
                              @keyup="getBudgetMinValue"
                            />
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.INDICACOSTO"
                                )
                              }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">
                              {{ $t("MODULEIDEACREATE.LOGO") }}</label
                            >
                            <vue-dropzone-4
                              ref="myVueDropzone4"
                              id="dropzone4"
                              :options="dropzoneOptions4"
                              :useCustomSlot="true"
                              @vdropzone-success="successUploadLogo"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">
                                  {{ $t("MODULEIDEACREATE.TRASCINA") }}
                                </h3>
                                <div class="subtitle">
                                  {{ $t("MODULEIDEACREATE.FILEACCETTATI") }}
                                </div>
                              </div>
                            </vue-dropzone-4>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.IMMAGINERAPPRESENTATIVA"
                                )
                              }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder"
                              >{{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.VIDEO"
                                )
                              }}
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="youtubeLink"
                              v-bind:placeholder="$t('MODULEIDEACREATE.PH2')"
                              v-model="$v.form.youtubeLink.$model"
                            />
                            <!-- v-model="$v.form.youtubeLink.$model" -->
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.VIDEOHINT"
                                )
                              }}
                            </div>
                          </div>

                          <!--                          #qui contentSubtype -->
                          <div
                            class="form-group"
                            v-if="this.$showIfService(moduleContentType)"
                          >
                            <label class="font-weight-bolder">{{
                              $t("MODULEIDEACOMMONS.SOTTOCATEGORIA")
                            }}</label>
                            <select
                              class="form-control form-control-solid form-control-lg"
                              name="contentSubtype"
                              v-model="$v.form.contentSubtype.$model"
                            >
                              <option :value="null">
                                {{ $t("MODULEIDEACOMMONS.PH5") }}
                              </option>
                              <option
                                v-for="subtype in contentSubtypes"
                                :value="subtype.id"
                                :key="subtype.id"
                              >
                                {{ subtype.name }}
                              </option>
                            </select>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t("MODULEIDEACOMMONS.SELEZIONASOTTOCATEGORIA")
                              }}
                            </div>
                          </div>
                          <!--                          /contentSubtype -->

                          <div class="form-group">
                            <label class="font-weight-bolder">{{
                              $t(
                                "MODULE" +
                                  $handleContentTypeLabel(moduleContentType) +
                                  "CREATE.IMMAGINI"
                              )
                            }}</label>
                            <vue-dropzone-3
                              ref="myVueDropzone3"
                              id="dropzone3"
                              :options="dropzoneOptions3"
                              :useCustomSlot="true"
                              @vdropzone-success="successUpload"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">
                                  {{ $t("MODULEIDEACREATE.TRASCINAIMMAGINI") }}
                                </h3>
                                <div class="subtitle">
                                  {{
                                    $t("MODULEIDEACREATE.FILEACCETTATIIMMAGINI")
                                  }}
                                </div>
                              </div>
                            </vue-dropzone-3>
                          </div>

                          <div class="form-group">
                            <label class="font-weight-bolder">{{
                              $t("MODULEIDEACREATE.DOCALLEGATI")
                            }}</label>
                            <vue-dropzone-1
                              ref="myVueDropzone1"
                              id="dropzone1"
                              @vdropzone-success="successUpload"
                              :options="dropzoneOptions1"
                              :useCustomSlot="true"
                            >
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">
                                  {{ $t("MODULEIDEACREATE.TRASCINADOC") }}
                                </h3>
                                <div class="subtitle">
                                  {{ $t("MODULEIDEACREATE.FILEACCETTATIDOC") }}
                                </div>
                              </div>
                            </vue-dropzone-1>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.ALTROMATERIALE"
                                )
                              }}
                            </div>
                          </div>

                          <!--                          #qui url -->
                          <div class="form-group">
                            <label class="font-weight-bolder"
                              >{{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.URLSITO"
                                )
                              }}
                            </label>
                            <input
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="url"
                              v-bind:placeholder="$t('MODULEIDEACREATE.PH4')"
                              v-model="$v.form.url.$model"
                            />
                            <!-- v-model="$v.form.youtubeLink.$model" -->
                            <div class="form-text text-muted pt-2">
                              {{ $t("MODULEIDEACOMMONS.AGGIUNGIURL") }}
                            </div>
                          </div>
                          <!--                          /url -->
                        </div>

                        <!--Dettaglio-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="mb-10 font-weight-bold text-dark h2">
                            {{
                              $t(
                                "MODULE" +
                                  $handleContentTypeLabel(moduleContentType) +
                                  "CREATE.NARRAZIONE"
                              )
                            }}
                          </div>
                          <div class="form-group">
                            <vue-editor
                              id="storytelling"
                              :editor-toolbar="customToolbar"
                              :tag="'textarea'"
                              v-model="storytelling"
                              row="30"
                            >
                            </vue-editor>
                            <div class="form-text text-muted pt-2">
                              {{
                                $t(
                                  "MODULE" +
                                    $handleContentTypeLabel(moduleContentType) +
                                    "CREATE.NARRA"
                                )
                              }}
                            </div>
                          </div>
                        </div>

                        <!--Toolbar-->
                        <div
                          class="d-flex justify-content-between border-top align-items-center"
                        >
                          <div class="">
                            <button
                              class="btn btn-default font-weight-bold text-uppercase btn-lg pl-3 pr-2"
                              data-wizard-type="action-prev"
                            >
                              <i class="icon flaticon2-back icon-md"></i>
                            </button>
                          </div>

                          <!--                          <div class="form-group">-->
                          <!--                            <div class="radio-inline pt-8">-->
                          <!--                              <label class="radio">-->
                          <!--                                <input type="radio" id="uno" name="stato" value=1 v-model="$v.form.stato.$model" />-->
                          <!--                                <span></span>-->
                          <!--                                Salva in bozza-->
                          <!--                              </label>-->
                          <!--                              <label class="radio">-->
                          <!--                                <input type="radio" id="tre" name="stato" value=3 v-model="$v.form.stato.$model" checked="checked"/>-->
                          <!--                                <span></span>-->
                          <!--                                Salva e pubblica-->
                          <!--                              </label>-->
                          <!--                            </div>-->
                          <!--                          </div>-->

                          <div class="mt-5">
                            <router-link
                              :to="{
                                name: 'processModuleIdeaList',
                                params: {
                                  process_id: process_id,
                                  phase_id: phase_id,
                                  id: module_id,
                                },
                              }"
                              class="btn btn-secondary btn-lg font-weight-bold text-uppercase mr-2"
                            >
                              {{ $t("MODULEIDEACREATE.ANNULLA") }}
                            </router-link>

                            <button
                              v-on:click="submitDraft"
                              class="btn btn-primary btn-lg font-weight-bolder text-uppercase mr-2"
                              data-wizard-type="action-submit"
                            >
                              {{ $t("MODULEIDEACREATE.BOZZA") }}
                            </button>
                            <button
                              v-on:click="submitPublic"
                              class="btn btn-primary btn-lg font-weight-bolder text-uppercase"
                              data-wizard-type="action-submit"
                            >
                              {{ $t("MODULEIDEACREATE.PUBBLICA") }}
                            </button>

                            <button
                              class="btn btn-primary btn-lg font-weight-bold text-uppercase"
                              data-wizard-type="action-next"
                            >
                              {{ $t("MODULEIDEACREATE.AVANTI") }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";

.custom-underline {
  position: relative;
  display: inline-block;
}

.custom-underline::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 4px;
  background-color: orange;
  width: 100%;
  //margin-bottom: 10px;
}

/* togliere cursori da inpute type==number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>

<script>
import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { SET_CURRENT_PROCESS } from "@/core/services/store/processes.module";
import Phaseline from "@/view/layout/common/Phaseline";

import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {
  minLength,
  required,
  integer,
  maxValue,
} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import JwtService from "@/core/services/jwt.service";

import { processMixin } from "@/mixins/process";
import Swal from "sweetalert2";

import { VueEditor } from "vue2-editor";
import { gmapApi } from "gmap-vue";
import GoogleMaps from "@/core/services/googlemaps.service.js";
import { ideaMixin } from "@/mixins/idea";
import i18nService from "@/core/services/i18n.service";
// // eslint-disable-next-line no-unused-vars
// import step from "@/assets/plugins/formvalidation/src/js/validators/step.ts";

export default {
  mixins: [ideaMixin, validationMixin, processMixin],
  name: "ModuleIdeaCreate",
  components: {
    Phaseline,
    VueEditor,
    vueDropzone1: vue2Dropzone,
    vueDropzone3: vue2Dropzone,
    vueDropzone4: vue2Dropzone,
  },
  data() {
    return {
      lang: "it",
      contentSubtypes: [],
      stepA: 1,
      stepB: 2,
      stepC: 3,
      stepD: 4,
      stepE: 5,

      moduleContentType: null,

      limitDescription: 600, // #errore maxLength

      hasForbiddenCharacters: null,
      budgetMinValue: null,

      hasProcessManyPhases: null,
      hasPhaseManyModules: null,

      /***************
       * # visualizzazione numeri in wizard nav
       ***************/
      istruzioniValue: null,
      challengesValue: null,
      scenariosValue: null,
      overviewValue: null,
      narrazioneValue: null,

      displayViewMap: false,
      contentTypeInstructions: "",
      ideaStatus: 1,
      module_id: this.$route.params.module_id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id,
      percentage: 0,
      languages: [],
      process: [],
      phase: [],
      module: [],
      challenges: [],
      agendas: [],
      scenarios: [],
      resources: [],
      ideaAddress: "",
      ideaPoint: "",
      logo: 0,
      storytelling: "",

      form: {
        contentSubtype: null,
        url: "",
        title: "",
        description: "",
        budgetMin: "",
        scenarios: [],
        challenges: [],
        youtubeLink: "",
        stato: "",
        address: "",
      },

      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],

      dropzoneOptions1: {
        url:
          this.$baseUrl + this.$apiResourceOpen + "/resources/doc/idea_content",
        thumbnailWidth: 150,
        maxFilesize: 20,
        acceptedFiles: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf",

        addRemoveLinks: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
        params: {
          metadata: new Blob(
            [
              JSON.stringify({
                title: "",
                description: "",
              }),
            ],
            {
              type: "application/json",
            }
          ),
        },
      },

      dropzoneOptions3: {
        url:
          this.$baseUrl +
          this.$apiResourceOpen +
          "/resources/image/idea_content/not_applicable",
        thumbnailWidth: 150,
        maxFilesize: 20,
        acceptedFiles: ".jpg, jpeg, .png, .gif",

        addRemoveLinks: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
        params: {
          metadata: new Blob(
            [
              JSON.stringify({
                title: "",
                description: "",
              }),
            ],
            {
              type: "application/json",
            }
          ),
        },
      },

      dropzoneOptions4: {
        url:
          this.$baseUrl +
          this.$apiResourceOpen +
          "/resources/image/not_applicable/logo",
        thumbnailWidth: 150,
        maxFiles: 1,
        maxFilesize: 20,
        addRemoveLinks: true,
        acceptedFiles: ".jpg, .jpeg, .png, .gif",
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
        params: {
          metadata: new Blob(
            [
              JSON.stringify({
                title: "",
                description: "",
              }),
            ],
            {
              type: "application/json",
            }
          ),
        },
      },

      /*
      |--------------------------------------------------------------------------
      | MAPS
      |--------------------------------------------------------------------------
      */
      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      zoom: 14,
      markers: [],
      place: null,
    };
  },
  validations: {
    form: {
      contentSubtype: {
        required,
      },
      title: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
      },
      budgetMin: {
        //required
        integer,
        maxValue: maxValue(1000000000),
        // myRule: (value) => (console.log('validations.myRule: ', value), !value.includes(',')),
      },
      scenarios: {
        // required
      },
      challenges: {
        // required
      },
      youtubeLink: "",
      url: "",
      // stato: {
      //   required
      // }
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentProcess",
      //"getCurrentPhase",
      //"getPercentage",
      "currentUser",
    ]),

    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },

    /*
    |--------------------------------------------------------------------------
    | GEOMAPS
    |--------------------------------------------------------------------------
    */
    mapCoordinates() {
      if (!this.map) {
        return this.$defaultCoordinates;
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
    google: gmapApi,
  },
  async mounted() {
    this.languages = this.getLanguages();
    this.lang = i18nService.getActiveLanguage();
    console.debug("lang: ", this.lang);
    this.process = await this.getProcess(this.process_id);
    console.debug(`phase id: `, this.phase_id);
    this.phase = this.getCurrentPhaseLocal(this.phase_id);
    this.module = this.getCurrentModule(this.process, this.module_id);
    this.moduleContentType = this.module?.contentType;

    this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;

    let current_module = this.process.process2Modules.filter((item) => {
      return item.id == this.module_id;
    });

    this.scenarios = this.process.scenarios;
    this.challenges = current_module[0].challenges;
    const agendaIds = this.challenges
      .map((x) => x.agendaId)
      .filter((value, index, array) => array.indexOf(value) === index);

    for (let aId of agendaIds) {
      var endpoint = this.generateUrl(`agendas/${aId}`, true);
      //generateUrl is done
      ApiService.get(this.$apiResource, endpoint).then((res) => {
        this.agendas.push({
          id: res.data.id,
          name: res.data.name || "",
          challenges: this.challenges.filter((x) => x.agendaId == res.data.id),
        });
      });
    }

    this.contentTypeInstructions =
      this.module.localizations[0] &&
      this.module.localizations[0].contentTypeInstructions
        ? this.module.localizations[0].contentTypeInstructions
        : "";

    // nuovo: centrare mappa
    if (this.process.cities[0]) {
      let cityPoint = this.process.cities[0].centroid;
      this.myCoordinates = this.pointToCoordinates(cityPoint);
    } else if (this.process.cities.length < 1 && localStorage.center) {
      this.myCoordinates = JSON.parse(localStorage.center);
    } else {
      this.$getLocation({})
        .then((coordinates) => {
          this.myCoordinates = coordinates;
        })
        .catch(() => {
          this.myCoordinates = this.$defaultCoordinates;
        });
    }
    // / centrare mappa

    if (localStorage.zoom) {
      this.zoom = parseInt(localStorage.zoom);
    }

    if (this.process?.cities?.length) {
      this.displayViewMap = true;
      this.$refs?.mapRef?.$mapPromise?.then((map) => (this.map = map));
    }

    // contentSubtype
    this.contentSubtypes = this.getContentSubtypes(
      this.lang,
      this.moduleContentType
    ).then((res) => {
      this.contentSubtypes = res.data;
      if (
        this.contentSubtypes == null ||
        this.contentSubtypes == [] ||
        this.contentSubtypes == ""
      ) {
        this.form.contentSubtype = true;
      }
      console.debug("#tsc: ", this.contentSubtypes);
    });

    /***************
     * # visualizzazione numeri wizard nav
     ***************/
    // this.istruzioniValue = this.contentTypeInstructions && this.contentTypeInstructions!='' ? 1 : 0;
    // this.challengesValue = this.challenges && this.challenges.length > 0 ? 1 : 0;
    // this.scenariosValue = ((this.scenarios && this.scenarios.length) || this.displayViewMap) > 0 ? 1 : 0;
    // this.overviewValue = this.istruzioniValue + this.challengesValue + this.scenariosValue + 1;
    // this.narrazioneValue = this.overviewValue + 1;

    // nuovo
    this.stepA =
      this.contentTypeInstructions && this.contentTypeInstructions != ""
        ? 1
        : 0;
    this.stepB =
      this.challenges && this.challenges.length > 0
        ? 1 + this.stepA
        : this.stepA;
    this.stepC =
      (this.scenarios && this.scenarios.length) > 0 || this.displayViewMap
        ? 1 + this.stepB
        : this.stepB;
    this.stepD = 1 + this.stepC;
    this.stepE = 1 + this.stepD;

    //  breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      {
        title: this.process.title,
        route: this.hasProcessManyPhases
          ? "/process-detail/" + this.process_id
          : "disabled",
      },
      // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
      {
        title: this.phase.title,
        route: this.hasPhaseManyModules
          ? `/process-phase/${this.phase_id}/${this.process_id}`
          : "disabled",
      },
      {
        title: this.module.title,
        route:
          "/idea-list/" +
          this.module_id +
          "/" +
          this.process_id +
          "/" +
          this.phase_id,
      },
      {
        title: this.$t(
          "MODULE" +
            this.$handleContentTypeLabel(this.moduleContentType) +
            "CREATE.CREAIDEA"
        ),
      },
    ]);

    // old
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
    //   { title: this.process.title, route: "/process-detail/" + this.process_id },
    //   { title: this.phase.title, route: `/process-phase/${this.phase_id}/${this.process_id}`},
    //   { title: this.module.title, route: "/idea-list/" + this.module_id + "/" + this.process_id + "/" + this.phase_id },
    //   { title: this.$t("MODULEIDEACREATE.CREAIDEA") }
    // ]);

    setTimeout(() => {
      // Initialize form wizard
      const wizard = new KTWizard("kt_wizard_v4", {
        startStep: 1, // initial active step number
        clickableSteps: true, // allow step clicking
      });

      // Validation before going to next page
      wizard.on("beforeNext", (wizardObj) => {
        // validate the form and use below function to stop the wizard's step

        if (wizardObj.getStep() === this.stepD) {

          this.$v.form.$touch();

          if (this.$v.form.$anyError || this.hasForbiddenCharacters) {
            Swal.fire({
              title: this.$t("MODULEIDEACREATE.ALERT1TITLE"),
              text:
                this.$v.form.title.$anyError ||
                this.$v.form.description.$anyError ||
                this.$v.form.contentSubtype.$anyError
                  ? this.$t(
                      "MODULE" +
                        this.$handleContentTypeLabel(this.moduleContentType) +
                        "CREATE.ALERT1"
                    )
                  : this.$t(
                      "MODULE" +
                        this.$handleContentTypeLabel(this.moduleContentType) +
                        "CREATE.ALERT3"
                    ),
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            }).then(() => {
              setTimeout(() => {
                KTUtil.scrollTop();
              }, 500);
            })

            wizardObj.stop();
          }  
        }
      });

      // Change event
      wizard.on("change", function () {
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 500);
      });
    }, 100);
  },
  created() {
    // this.languages = this.getLanguages();
    // this.process = this.getProcess(this.process_id)
    // console.debug(`phase id: `, this.phase_id);
    // this.phase = this.getCurrentPhaseLocal(this.phase_id);
    // this.module = this.getCurrentModule(this.process, this.module_id);
    // this.moduleContentType = this.module?.contentType;
    //
    // this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    // this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;
    //
    // let current_module = this.process.process2Modules.filter(item => {
    //   return item.id == this.module_id;
    // });
    //
    // this.challenges = current_module[0].challenges;
    // this.scenarios = this.process.scenarios;
    //
    // this.contentTypeInstructions = (this.module.localizations[0] && this.module.localizations[0].contentTypeInstructions) ? this.module.localizations[0].contentTypeInstructions : '';
    //
    // // nuovo: centrare mappa
    // if ( this.process.cities[0] ) {
    //   let cityPoint = this.process.cities[0].centroid;
    //   this.myCoordinates = this.pointToCoordinates(cityPoint);
    // } else if ( this.process.cities.length <1 && localStorage.center) {
    //   this.myCoordinates = JSON.parse(localStorage.center);
    // } else {
    //   this.$getLocation({})
    //       .then(coordinates => {
    //         this.myCoordinates = coordinates;
    //       })
    //       .catch(() => {
    //         this.myCoordinates = this.$defaultCoordinates;
    //       })
    // }
    // // / centrare mappa
    //
    // if (localStorage.zoom) {
    //   this.zoom = parseInt(localStorage.zoom);
    // }
    //
    // if(this.process?.cities?.length) {
    //
    //   this.displayViewMap = true;
    //   this.$refs?.mapRef?.$mapPromise?.then(map => this.map = map);
    // }
    //
    // // contentSubtype
    // this.contentSubtypes = this.getContentSubtypes(this.moduleContentType).then(res => {
    //   this.contentSubtypes = res.data
    //   if(this.contentSubtypes == null || this.contentSubtypes == [] || this.contentSubtypes == '') {
    //     this.form.contentSubtype = true
    //   }
    //   console.debug('#tsc: ', this.contentSubtypes);
    // })
    //
    // /***************
    // * # visualizzazione numeri wizard nav
    // ***************/
    // this.istruzioniValue = this.contentTypeInstructions && this.contentTypeInstructions!='' ? 1 : 0;
    // this.challengesValue = this.challenges && this.challenges.length > 0 ? 1 : 0;
    // this.scenariosValue = ((this.scenarios && this.scenarios.length) || this.displayViewMap) > 0 ? 1 : 0;
    // this.overviewValue = this.istruzioniValue + this.challengesValue + this.scenariosValue + 1;
    // this.narrazioneValue = this.overviewValue + 1;
  },
  methods: {
    // #errore maxLength
    isMaxLengthErrorVisible(element) {
      if (element == "description") {
        return (
          this.$ideaForm[element].maxLength -
            document?.querySelector("#edit")?.innerText?.length <=
          this.$ideaForm[element].limitBeforeAlert
        );
      }
      return (
        this.$ideaForm[element].maxLength -
          this.$v.form[element].$model.length <=
        this.$ideaForm[element].limitBeforeAlert
      );
    },

    hasReachTheLimit(element) {
      if (element == "description") {
        return (
          this.$ideaForm[element].maxLength -
            document?.querySelector("#edit")?.innerText?.length <=
          this.$ideaForm[element].limitBeforeAlert
        );
      }
      return (
        this.$ideaForm[element].maxLength -
          this.$v.form[element].$model.length <=
        this.$ideaForm[element].limitBeforeAlert
      );
    },

    howManyCharactersLleft(element) {
      if (element == "description") {
        return (
          this.$ideaForm[element].maxLength -
          document?.querySelector("#edit")?.innerText?.length
        );
      }
      return (
        this.$ideaForm[element].maxLength - this.$v.form[element].$model.length
      );
    },

    onTextChange() {
      const quill = this.$refs.editorDescription.quill;
      const len = quill.getLength();
      if (len > this.limitDescription) {
        quill.deleteText(this.limitDescription, len);
      }
    },
    // /

    handleCancel() {
      console.debug();
    },
    getBudgetMinValue(e) {
      console.log("getBudgetMinValue: ", e.key);
      e.key == "." || e.key == "," || e.key == "e"
        ? (this.budgetMinValue = e.key)
        : (this.budgetMinValue = e.target._value);

      console.log("budgetMinValue: ", this.budgetMinValue);

      if (this.budgetMinValue != null || this.budgetMinValue != "")
        this.checkForbiddenCharacters();
    },

    checkForbiddenCharacters() {
      console.debug("checkForbiddenCharacters: ", this.budgetMinValue);
      console.debug(
        "checkForbiddenCharacters $model: ",
        this.$v.form.budgetMin.$model
      );
      // eslint-disable-next-line no-useless-escape
      if (/[,\.e]/.test(this.budgetMinValue)) {
        this.hasForbiddenCharacters = true;
      } else {
        this.hasForbiddenCharacters = false;
      }
      console.debug("hasForbiddenCharacters: ", this.hasForbiddenCharacters);
    },

    /*
    |--------------------------------------------------------------------------
    | MAPS
    |--------------------------------------------------------------------------
    */
    setCityPoint() {
      return this.process.cities[0] ? this.process.cities[0].centroid : "";
    },

    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;
    },

    setDescription(description) {
      this.description = description;
    },

    setPlace(place) {
      if (place.geometry && place.geometry != undefined) {
        this.place = place;
        this.ideaAddress = place.formatted_address;
        this.ideaPoint =
          "POINT (" +
          place.geometry.location.lng() +
          " " +
          place.geometry.location.lat() +
          ")";
        this.map.panTo({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      } else {
        this.place = null;
        this.form.address = "";
        this.ideaAddress = "";
        this.ideaPoint = null;
        this.idea.centroid = null;
      }
    },

    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          },
        });
        this.place = null;
      }
    },

    /*
    |--------------------------------------------------------------------------
    | OTHER
    |--------------------------------------------------------------------------
    */

    getCurrentPhaseLocal(id) {
      for (let phase of this.process.processPhases) {
        // console.debug(`getcurrentphaselocal processphases: `, phase);
        if (phase.id == id) {
          // console.debug(`getcurrentphaselocal current processphases: `, phase);

          return phase;
        }
      }
      // console.debug(`getcurrentphaselocal : no phase returned`);
      return {};
    },

    validateState(name) {
      // console.debug('validate state', name, this.$v.form)
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submitPublic(e) {
      this.ideaStatus = 3;
      this.submit(e);
    },

    submitDraft(e) {
      this.ideaStatus = 1;
      this.submit(e);
    },

    async submit(e) {
      e.preventDefault();

      this.$v.form.$touch();

      if (this.$v.form.$anyError || this.hasForbiddenCharacters) {
        console.debug("validation errors: ", this.$v.form);
        Swal.fire({
          title: this.$t("MODULEIDEACREATE.ALERT1TITLE"),
          text:
            this.$v.form.title.$anyError ||
            this.$v.form.description.$anyError ||
            this.$v.form.contentSubtype.$anyError
              ? this.$t(
                  "MODULE" +
                    this.$handleContentTypeLabel(this.moduleContentType) +
                    "CREATE.ALERT1"
                )
              : this.$t(
                  "MODULE" +
                    this.$handleContentTypeLabel(this.moduleContentType) +
                    "CREATE.ALERT3"
                ),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });

        return;
      }

      // Inserisco l'idea
      let idea_id = await this.addIdea();

      Swal.fire({
        title: "",
        text: this.$t(
          "MODULE" +
            this.$handleContentTypeLabel(this.moduleContentType) +
            "CREATE.ALERT2"
        ),
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });

      //Torno al modulo
      if (idea_id > 0) {
        this.$router.push({
          name: "processModuleIdeaDetail",
          params: {
            id: idea_id,
            process_id: this.process_id,
            phase_id: this.phase_id,
            module_id: this.module_id,
          },
        });
      }
    },

    // Dropzone Methods
    uploadProgress() {},
    // fileAdded(file) {
    //   console.log('file: ' + JSON.stringify(file));
    // },
    sendingFiles() {},
    successUpload(file, res) {
      // console.log('file: ' + JSON.stringify(file));
      // console.log('res: ' + JSON.stringify(res));
      this.resources.push({
        id: res.id,
      });
    },
    successUploadLogo(file, res) {
      this.logo = res.id;
    },

    uploadLink() {
      return new Promise((resolve, reject) => {
        let youtubeLinkData = {
          title: "Link youtube idea",
          description: "",
          uri:
            "//www.youtube.com/embed/" +
            this.getEmbededUrl(this.form.youtubeLink),
        };
        var endpoint = this.generateUrl(
          "/resources/extsvc/idea_content",
          true,
          false
        );
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, youtubeLinkData)
          .then((res) => {
            // Aggiungo il nuovo link
            this.resources.push({
              id: res.data.id,
            });
            resolve(res.data.id);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async addIdea() {
      this.$isLoading(true);
      let challenges_selected = [];
      let scenarios_selected = [];

      for (let item of this.form.challenges) {
        challenges_selected.push({
          id: item,
        });
      }

      for (let item of this.form.scenarios) {
        scenarios_selected.push({
          id: item,
        });
      }

      if (this.form.youtubeLink) {
        // Carico la nuova risorsa e aggiorno l'array delle risorse
        await this.uploadLink();
      }

      var put_localizations = [];
      var localizations = [];
      var localizated_address = "";
      var recordNative = false;
      if (
        this.place != null &&
        this.place.geometry.location.lng() &&
        this.place.geometry.location.lat()
      ) {
        if (this.languages != null) {
          for (const lang of this.languages) {
            if (!localizations.find((loc) => loc.locale == lang.locale)) {
              recordNative =
                i18nService.getActiveLanguage() == lang.locale ? true : false;
              localizations[lang.locale] = {
                locale: lang.locale,
                title: this.form.title,
                description: this.form.description,
                address: this.ideaAddress,
                narration: this.storytelling,
                recordNative: recordNative,
              };
            }

            localizated_address = await GoogleMaps.getByAddress(
              this.ideaAddress,
              lang.locale
            );

            if (localizated_address && localizated_address != "") {
              localizations[lang.locale].address = localizated_address;
            }

            put_localizations.push(localizations[lang.locale]);
          }
        }
      }
      let onlyTextDescription = this.form.description.replace(/<[^>]*>/g, "");
      let ideaData = {
        process2ModuleId: this.module_id,
        ideaStatus: {
          id:
            this.place != null &&
            this.place.geometry.location.lng() &&
            this.place.geometry.location.lat()
              ? 1
              : this.ideaStatus,
        },
        title: this.form.title,
        description: onlyTextDescription,
        budgetMin: this.form.budgetMin || 0, // #qui: applica modifica (togli seconda condizione)
        challenges: challenges_selected,
        scenarios: scenarios_selected,
        resources: this.resources,
        centroid: this.ideaPoint || null,
        address: this.ideaAddress,
        narration: this.storytelling,
        contentSubtype:
          this.contentSubtypes == null ||
          this.contentSubtypes == [] ||
          this.contentSubtypes == ""
            ? null
            : { id: this.form.contentSubtype },
        url: this.form.url,
      };

      console.debug("#sct: ", this.form.contentSubtype);

      if (this.logo > 0) {
        ideaData.logo = { id: this.logo };
      }

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("/ideas", true, false);
        //generateUrl is done
        console.log("prova", this.form.description);
        ApiService.post(this.$apiResourceOpen + endpoint, ideaData)
          .then(async (res) => {
            if (
              this.place != null &&
              this.place.geometry.location.lng() &&
              this.place.geometry.location.lat()
            ) {
              endpoint = this.generateUrl(
                "/ideas/" + res.data.id + "/locs",
                false,
                false
              );
              //generateUrl is done
              ApiService.put(
                this.$apiResourceOpen + endpoint,
                put_localizations
              )
                .then((res2) => {
                  console.log("Idea localizzata: ", res2.data);

                  if (this.ideaStatus == 3) {
                    endpoint = this.generateUrl(
                      "/ideas/" + res2.data.id + "/idea-status/publish",
                      false,
                      false
                    );
                    //generateUrl is done
                    ApiService.put(this.$apiResourceOpen + endpoint)
                      .then((res3) => {
                        this.$isLoading(false);
                        resolve(res3.data.id);
                      })
                      .catch((e) => {
                        console.log("ERROR", e.message);
                        reject(0);
                      });
                  } else {
                    this.$isLoading(false);
                    resolve(res2.data.id);
                  }

                  /*chiedere a carmelo
                    let ideaDataUpdate = {
                      id: res2.data.id,
                      process2ModuleId: this.module_id,
                      ideaStatus: {
                        id: this.ideaStatus
                      },
                      budgetMin: res2.data.budgetMin,
                      centroid: res2.data.centroid,
                      localizations: put_localizations,
                    };

                    if(this.logo > 0) {
                      ideaData.logo = {
                        "id": this.logo
                      }
                    }


                    ApiService.put(this.$apiResourceOpen + "/ideas-unassociated-locsnode", ideaDataUpdate)
                    .then(res3 => {

                      this.$isLoading(false);
                      resolve(res3.data.id);
                    })
                    .catch((e) => {
                      console.log("ERROR", e.message);
                      reject(0);
                    });
                    end::chiedere a carmelo*/
                })
                .catch((e) => {
                  console.log("ERRORE", e.message);
                  reject(0);
                });
            } else {
              this.$isLoading(false);
              resolve(res.data.id);
            }
          })
          .catch((e) => {
            console.log("ERRORE", e.message);
            reject(0);
          });
      });
    },
  },
};
</script>
